@import "styles/variables";
@import "styles/mixins";

.tag {
  padding: $spacing-3/2 $spacing-3;
  color: var(--color-on-primary-action);
  display: inline-block;
  position: relative;

  .tagLabel {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: $spacing-8;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 0.5;
  }

  &.pink {
    &::after {
      background: var(--color-pink-base);
    }
  }
  &.pinkYin {
    &::after {
      background: var(--color-pink-yin);
    }
  }
  &.orange {
    &::after {
      background: var(--color-orange-base);
    }
  }
  &.orangeYang {
    &::after {
      background: var(--color-orange-yang);
    }
  }
  &.green {
    &::after {
      background: var(--color-green-yang);
    }
  }
  &.greenYin {
    &::after {
      background: var(--color-green-yin);
    }
  }
  &.darkGreen {
    &::after {
      background: var(--color-dark-text);
    }
  }
  &.yellow {
    &::after {
      background: var(--color-yellow-base);
    }
  }
  &.yellowYin {
    &::after {
      background: var(--color-yellow-yin);
    }
  }
  &.blue {
    &::after {
      background: var(--color-blue-yin);
    }
  }
  &.blueYang {
    &::after {
      background: var(--color-blue-yang);
    }
  }
  &.blueYin {
    &::after {
      background: var(--color-blue-base);
    }
  }
  &.violet {
    &::after {
      background: var(--color-violet-yang);
    }
  }
  &.red {
    &::after {
      background: var(--color-red-yin);
    }
  }
}

@import "styles/variables";
@import "styles/mixins";

// Minimum Media Height
$promoted-event-media-height-mobile: 160px;
$promoted-event-media-height-tablet: 460px;
$promoted-event-media-height-desktop: 476px;

// Minimum Media Height for Full Width
$promoted-event-full-media-height-mobile: 576px;
$promoted-event-full-media-height-tablet: 532px;
$promoted-event-full-media-height-desktop: 612px;

// Box width
$promoted-event-box-width-mobile: 100%;
$promoted-event-box-width-tablet: 420px;
$promoted-event-box-width-desktop: 496px;

// Box width for Full Width
$promoted-event-full-box-width-tablet: 459px;

// Total max width
$promoted-event-max-width: 1368px;

.wrapper {
  overflow: initial;
}

.contentWrapper {
  position: relative;

  @include media("desktop-medium") {
    padding: 0;
    max-width: $promoted-event-max-width;
  }

  .fullWidth & {
    max-width: 100%;
    padding: 0;
  }
}

.mediaWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: $promoted-event-media-height-mobile;

  @include media("tablet-portrait") {
    min-height: $promoted-event-media-height-tablet;
    max-height: 100%;
  }

  @include media("desktop-small") {
    min-height: $promoted-event-media-height-desktop;
  }

  .fullWidth & {
    height: $promoted-event-full-media-height-mobile;

    @include media("tablet-landscape") {
      height: $promoted-event-full-media-height-tablet;
    }

    @include media("desktop-small") {
      height: $promoted-event-full-media-height-desktop;
    }

    .image {
      position: absolute;
      max-height: 100%;
    }
  }

  .image {
    width: 100%;
    height: $promoted-event-media-height-mobile;
    object-fit: cover;
    position: relative;
    z-index: 2;

    @include media("tablet-portrait") {
      height: 100%;
      position: absolute;
    }
  }

  .videoPlayer {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: $promoted-event-media-height-mobile;

    .fullWidth & {
      max-height: 100%;
    }

    @include media("tablet-portrait") {
      max-height: 100%;
    }
  }
}

.boxWrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  padding: 0;
  overflow: hidden;

  @include media("tablet-portrait") {
    padding: $spacing-10;
  }

  @include media("tablet-portrait-max") {
    .fullWidth & {
      max-width: 100%;
    }
  }

  &.centered {
    justify-content: center;
  }

  &.rightAligned {
    justify-content: flex-end;
  }
}

.boxGridContainer {
  width: 100%;

  @include media("tablet-landscape") {
    width: auto;
    padding-left: $grid-gutter-desktop;
    padding-right: $grid-gutter-desktop;
  }
}

.box {
  padding: $spacing-6;
  text-align: center;
  max-width: $promoted-event-box-width-mobile;

  @include media("tablet-landscape") {
    padding: $spacing-12;
    max-width: $promoted-event-box-width-tablet;
  }

  @include media("desktop-small") {
    max-width: $promoted-event-box-width-desktop;
  }

  .fullWidth & {
    text-align: center;

    @include media("tablet-landscape") {
      text-align: left;
      max-width: $promoted-event-full-box-width-tablet;
    }

    &.textCenter {
      text-align: center;
    }

    &.textRight {
      text-align: right;
    }
  }
}

.subtitle {
  margin-bottom: $spacing-2;
  color: var(--color-fill-3);
}

.title {
  margin-bottom: $spacing-2;
}

.description {
  color: var(--color-fill-2);
}

.mainButton {
  margin-top: $spacing-9;
}

.blurredImage {
  position: absolute;
  top: $spacing-4;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(100% - #{$spacing-8});
  height: 100%;
  opacity: 0.4;
  filter: blur(22px);
  z-index: 1;
}

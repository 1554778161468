@import "styles/variables";

.container {
  :global(.rc-collapse-item-active) {
    .title {
      color: var(--color-primary-action-text);
    }
  }
}

.headerClass {
  display: flex;
  flex-direction: row;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
  margin: $spacing-4 0;

  .title {
    flex: 1;
  }

  &:focus {
    outline: 0;
  }
}

.icon {
  font-size: $spacing-5;
  color: var(--color-primary-action-text);
}

.content {
  margin-bottom: $spacing-4;
}

:global(.rc-collapse) {
  &-motion {
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  &-content-hidden {
    display: none;
  }
}

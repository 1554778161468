@import "styles/variables";
@import "styles/mixins";

.container {
  p {
    margin-bottom: $spacing-4;
  }
  h1,
  h1,
  h3 {
    margin: $spacing-6 0;
  }
}

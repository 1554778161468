@import "styles/variables";
@import "styles/mixins";

$hexagon-size-tablet: 338px;
$hexagon-size-desktop: 492px;

.wrapper {
  padding-top: 0;
  padding-bottom: $spacing-10;

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}

.hexagon {
  position: absolute;
  top: -78px;
  right: -89px;
  font-size: $hexagon-size-tablet;

  @include media("desktop-medium") {
    font-size: $hexagon-size-desktop;
    top: -79px;
    right: -121px;
  }
}

.imageWrapper {
  padding-bottom: $image-aspect-ratio-3-to-4;
  margin-bottom: $spacing-10;

  @include media("tablet-landscape") {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: $spacing-4;
}

.subheader {
  margin-bottom: $spacing-2;
}

.contetWrapper {
  display: flex;
  align-items: center;
}

.tagsWrapper {
  margin-bottom: $spacing-6;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-10;
  }

  .tag {
    margin-right: $spacing-2;
    margin-bottom: $spacing-2;
  }
}

.description {
  margin-bottom: $spacing-2;
}

.link {
  color: var(--color-primary-action-text);
  font-size: pxToRem(17);
  text-decoration: underline;
}

.emptyCol {
  display: none;

  @include media("desktop-small") {
    display: initial;
  }
}

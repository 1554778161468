@import "styles/variables";
@import "styles/mixins";

.graphic {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -#{$auth-layout-content-spacing-mobile};
  margin-bottom: $spacing-8;

  @include media("tablet-landscape") {
    margin-top: -#{$auth-layout-content-spacing-tablet};
    margin-bottom: -#{$spacing-4};
  }
}

.title {
  margin-bottom: $spacing-2;
}

// Spacing
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-7: 28px;
$spacing-8: 32px;
$spacing-9: 36px;
$spacing-10: 40px;
$spacing-11: 44px;
$spacing-12: 48px;
$spacing-13: 52px;
$spacing-14: 56px;
$spacing-15: 60px;
$spacing-16: 64px;
$spacing-17: 68px;
$spacing-18: 76px;
$spacing-20: 80px;
$spacing-22: 88px;
$spacing-24: 96px;
$spacing-30: 120px;

//Icon sizes
$icon-size-xs: 22px;
$icon-size-s: 24px;

// Section
$section-vertical-spacing-mobile: $spacing-10;
$section-vertical-spacing-desktop: $spacing-15;

// Grid
$grid-gutter-mobile: $spacing-3 / 2;
$grid-gutter-desktop: $spacing-6 / 2;

// Navigation
$navigation-height-default: 64px;
$navigation-height-large: 100px;

// Auth Layout
$auth-layout-content-spacing-mobile: $spacing-16;
$auth-layout-content-spacing-tablet: $spacing-14;

// Images Aspect Ratios
$image-aspect-ratio-3-to-4: 125%;
$image-aspect-ratio-3-to-2: 66.667%;
$image-aspect-ratio-5-to-3: 60%;
$image-aspect-ratio-16-to-9: 56.25%;
$image-aspect-ratio-5-to-2: 40%;

// inputs
$input-height: 40px;

// Video Session
$video-sidebar-width-tablet: 325px;
$video-sidebar-width-desktop: 342px;
// Video Session Toolbar
$video-toolbar-height-mobile: $spacing-16;
$video-toolbar-height-desktop: $spacing-20;
$video-toolbar-spacing-mobile: $spacing-4;
$video-toolbar-spacing-desktop: $spacing-8;

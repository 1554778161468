@import "styles/variables";
@import "styles/mixins";

.profile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .user {
    display: inline-flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: $spacing-5;

    @include media("tablet-landscape") {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
    }
  }

  .userName {
    margin-left: $spacing-3;
    margin-right: 0;

    @include media("tablet-landscape") {
      margin-right: $spacing-3;
      margin-left: 0;
    }
  }
}

.menuWrapper {
  @include media("tablet-landscape") {
    position: absolute;
    top: $spacing-13;
    right: 0;
    transition: max-height 0.5s ease;
    max-height: 0;
    overflow: hidden;
    background: #65776c;
  }

  &.opened {
    max-height: 500px;
  }

  .menu {
    margin: 0;
    list-style: none;
    padding: $spacing-4;
    padding-left: 0;

    @include media("tablet-landscape") {
      padding: $spacing-4;
    }

    .menuItem {
      min-width: $spacing-30;
      cursor: pointer;
      text-align: left;

      &:not(:last-of-type) {
        margin-bottom: $spacing-4;
      }
    }
  }
  .link {
    text-decoration: none;
  }
}

@import "styles/variables";
@import "styles/mixins";

.navItem {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  margin-bottom: $spacing-6;

  @include media("tablet-landscape") {
    margin-bottom: 0;
    margin-right: $spacing-6;
    justify-content: initial;
    align-items: initial;
  }

  &:last-of-type {
    @include media("tablet-landscape") {
      margin-right: 0;
    }
  }
}

:global(.imagery-theme) {
  .navItemLink {
    .linkText {
      color: var(--color-fill-3);
    }

    &:hover,
    &.active {
      .linkText {
        color: var(--color-fill-1);
      }
    }
  }
}

.navItemLink {
  text-decoration: none;

  &:hover,
  &.active {
    .linkText {
      color: var(--color-primary-action-text);
      transition: 300ms color ease-in-out;
    }
  }
}

.spacingBottom {
  margin-bottom: $spacing-3;

  @include media("tablet-landscape") {
    margin-bottom: 0;
    padding-bottom: $spacing-10;
  }
}

.menuDrop {
  width: $spacing-6;
  height: $spacing-6;
  &.activeIcon {
    color: var(--color-primary-action-text);
  }
}

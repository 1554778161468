@import "styles/variables";
@import "styles/mixins";

.wrapper {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  text-align: center;

  .illustration {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/Aura.svg");
    background-repeat: no-repeat;
    background-size: 140%;
    background-position: top;

    @include media("tablet-portrait") {
      background-size: 75%;
      background-position: center;
    }
  }
  .title {
    margin-bottom: $spacing-6;
  }
  .subTitle {
    margin-bottom: $spacing-15;
  }
}

@import "styles/variables";
@import "styles/mixins";

.title {
  margin-bottom: $spacing-2;
}

.logo {
  margin-top: -#{$spacing-8};
  margin-bottom: $spacing-6;

  @include media("tablet-landscape") {
    display: none;
  }
}

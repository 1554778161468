.white-theme {
  --color-fill-1: #54685c;
  --color-fill-1-reverse: #ffffff;
  --color-fill-2: rgba(84, 104, 93, 0.75);
  --color-fill-3: rgba(84, 104, 93, 0.5);
  --color-separator: rgba(84, 104, 92, 0.15);
  --color-background-1: #ffffff;
  --color-background-1-reverse: #54685c; //The main background color for the Khaki Theme
  --color-background-2: #eef0ef;
}

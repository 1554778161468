@import "styles/variables";
@import "styles/mixins";

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@import "styles/variables";

.wrapper {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 9;
  pointer-events: none;
  background: var(--color-global-overlay);
  transition: 0.3s opacity ease;

  &.showWrapper {
    opacity: 1;
    pointer-events: auto;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  padding: $spacing-6 0;
  padding-top: $spacing-20;
  background-color: var(--color-background-1);
  transform: translateY(-100%);
  transition: 0.3s transform ease;
  overflow: auto;
  max-height: 100%;

  &.menuOpened {
    transform: translateY(0);
  }
}

.container {
  padding: 0 $spacing-6;
}

.divider {
  height: 2px;
  background: var(--color-separator);
  margin-bottom: 32px;
}

.buttons {
  width: 100%;
  padding: 0 $spacing-6;
  display: flex;
  justify-content: center;

  a {
    width: 100%;

    &:first-of-type {
      margin-right: $spacing-5/2;
    }

    &:last-of-type {
      margin-left: $spacing-5/2;
    }
  }
}

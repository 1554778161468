.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  line-height: 1;
  position: relative;

  .image {
    width: 100%;
    height: 100%;
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    }
    position: absolute;
    top: 0;
    z-index: 0;
  }

  .content {
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &:focus {
    outline: 0;
  }
}

.fakeImg {
  display: none;
}

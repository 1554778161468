@import "styles/variables";
@import "styles/mixins";

$quote-wrapper-width-tablet: 800px;
$swiper-nav-arrow-icon-half-height: $spacing-5;

.quotesSwiper {
  overflow: hidden !important;
  :global(.swiper-wrapper) {
    display: flex;
    align-items: center;
  }
}

.quoteWrapper {
  text-align: center;
  margin: 0 $spacing-3;

  @include media("tablet-portrait") {
    max-width: $quote-wrapper-width-tablet;
    margin: 0 auto;
  }

  .textBottomSpacing {
    margin-bottom: $spacing-6;

    @include media("tablet-portrait") {
      margin-bottom: $spacing-10;
    }
  }

  .author {
    color: var(--color-fill-3);
  }

  .quoteContent {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1.5em;
    font-size: 0.875em;
  }
}

@import "styles/variables";
@import "styles/mixins";

.wrapper {
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1;

  .input {
    appearance: auto;
    position: absolute;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.label {
  font-weight: 400;
}

.checkbox {
  width: $icon-size-s;
  height: $icon-size-s;
  border-radius: $spacing-1;
  border: 1px solid var(--color-separator);
  margin-right: $spacing-2;
}

.checkedIcon {
  color: var(--color-white);
  background: var(--color-primary-action);
  border-radius: $spacing-1;
  font-size: $icon-size-xs;
  display: block;
  margin-right: $spacing-2;
}

@import "styles/variables";
@import "styles/mixins";

.button,
.buttonContent {
  &:focus {
    outline: 0;
  }
}

.button {
  &.loading {
    cursor: default;
    pointer-events: none;
  }

  &.fullWidth {
    &,
    .buttonContent {
      width: 100%;
    }
  }

  .label {
    color: inherit;

    &.startIconLabel {
      padding-left: $spacing-2;
    }
    &.endIconLabel {
      padding-right: $spacing-2;
    }
  }

  @include media("tablet-portrait-max") {
    &.hideLabelOnMobile .label {
      display: none;
    }
  }

  svg {
    font-size: pxToRem(24);
  }

  &.isDisabled {
    pointer-events: none !important;
  }
}

.buttonContent {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .isDisabled & {
    background: transparent !important;
    color: var(--color-fill-3) !important;
    box-shadow: inset 0 0 0 1px var(--color-separator) !important;
    pointer-events: none !important;
    &.text {
      box-shadow: none !important;
    }
  }

  &.small {
    padding: $spacing-1/2 $spacing-2;
  }
  &.medium {
    padding: $spacing-1 $spacing-4;
  }
  &.large {
    padding: $spacing-2 $spacing-5;
  }
  &.text {
    background: transparent;
    padding: 0;
  }
}

.primary {
  background: var(--color-primary-action);
  color: var(--color-on-primary-action);

  &.text {
    color: var(--color-primary-action-text);
    transition: color 0.2s;

    .button:focus &,
    &:hover {
      color: currentColor;
    }
  }

  &:not(.text) {
    &:hover {
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
    }

    $primary-button-focus-border-offset: 2px;

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      border: solid 1px transparent;
      top: -#{$primary-button-focus-border-offset};
      bottom: -#{$primary-button-focus-border-offset};
      left: -#{$primary-button-focus-border-offset};
      right: -#{$primary-button-focus-border-offset};
      transition: all 0.2s;
    }

    .button:focus & {
      &:after {
        border-color: var(--color-primary-action-text);
      }
    }

    .button:active &,
    .button:visited & {
      &:after {
        border-color: transparent;
      }
    }

    .button:active & {
      background: var(--color-fill-1);
      color: var(--color-background-1);
    }
  }
}

.secondary {
  background: var(--color-background-1);
  color: var(--color-fill-1);

  &:not(.text) {
    box-shadow: inset 0 0 0 1px var(--color-fill-3);

    &:hover {
      box-shadow: inset 0 0 0 1px var(--color-fill-1);
    }

    .button:focus & {
      background: var(--color-background-2);
    }

    .button:active & {
      background: var(--color-fill-1);
      color: var(--color-background-1);
    }
  }
}

.link {
  text-decoration: none;
  color: inherit;

  &.linkDisabled {
    pointer-events: none;
  }
}

:root {
  --color-default-khaki: #54685c;
  --color-global-gold: linear-gradient(
    270deg,
    #b29164 0%,
    #ffda8a 60.83%,
    #b29164 124.24%
  );

  --live-session-gradient: linear-gradient(90deg, #bb93dd 0%, #ff6d2d 100%);
  --color-primary-action: linear-gradient(90deg, #b29164 0%, #ffda8a 100%);
  --color-primary-action-text: #d9b677;
  --color-on-primary-action: #ffffff;
  --color-global-overlay: rgba(64, 79, 71, 0.6);
  --color-dark-text: #2f3c34;
  --color-white: #ffffff;

  --color-semantics-warning: #ff6d2d;

  --color-violet-yang: #bb93dd;
  --color-violet-base: #4d008c;
  --color-violet-yin: #807390;

  --color-blue-yang: #3a96db;
  --color-blue-base: #3b44ac;
  --color-blue-yin: #445571;

  --color-green-yang: #00c496;
  --color-green-base: #006548;
  --color-green-yin: #719c8b;

  --color-yellow-yang: #ffe000;
  --color-yellow-base: #ffc03c;
  --color-yellow-yin: #f6cd80;

  --color-orange-yang: #ffa891;
  --color-orange-base: #ff6d2d;
  --color-orange-yin: #f47d4c;

  --color-red-yang: #fb5058;
  --color-red-base: #ce0e2d;
  --color-red-yin: #691c32;

  --color-pink-yang: #ffc9d3;
  --color-pink-base: #e6007e;
  --color-pink-yin: #934e70;
}

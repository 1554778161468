@import "styles/mixins";

%base {
  white-space: pre-line;
  word-break: break-word;
  padding: 0;
  margin: 0;
  color: var(--color-fill-1);

  &.gold {
    color: var(--color-primary-action-text);
  }
  &.goldGradient {
    background-image: var(--color-global-gold);
    background-size: 100%;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}
%large {
  text-transform: uppercase;
  letter-spacing: 0.1em;

  &:not(.isMobile) {
    @include media("tablet-landscape") {
      letter-spacing: 0.2em;
    }
  }
}

/* HEADING */
.heading {
  @extend %base;
  @extend %large;
  font-weight: 300;
}
.heading1 {
  font-size: pxToRem(44);
  line-height: pxToRem(48);

  @include media("tablet-landscape") {
    font-size: pxToRem(64);
    line-height: pxToRem(72);
  }
}
.heading2 {
  font-size: pxToRem(36);
  line-height: pxToRem(40);

  @include media("tablet-landscape") {
    font-size: pxToRem(55);
    line-height: pxToRem(64);
  }
}
.heading3 {
  font-size: pxToRem(26);
  line-height: pxToRem(32);

  @include media("tablet-landscape") {
    font-size: pxToRem(47);
    line-height: pxToRem(56);
  }
}

/* TITLE */
.title {
  @extend %base;
  @extend %large;
  font-weight: 500;
}
.title1 {
  font-size: pxToRem(24);
  line-height: pxToRem(32);

  &:not(.isMobile) {
    @include media("tablet-landscape") {
      font-size: pxToRem(30);
      line-height: pxToRem(40);
    }
  }
}
.title2 {
  font-size: pxToRem(18);
  line-height: pxToRem(24);

  &:not(.isMobile) {
    @include media("tablet-landscape") {
      font-size: pxToRem(20);
      line-height: pxToRem(28);
    }
  }
}
.title3 {
  font-size: pxToRem(13);
  line-height: pxToRem(24);
  font-weight: 700;

  &:not(.isMobile) {
    @include media("tablet-landscape") {
      font-size: pxToRem(14);
    }
  }
}

/* SUBTITLE */
.subtitle {
  @extend %base;
  font-weight: 500;
}
.subtitle1 {
  font-weight: 400;
  font-size: pxToRem(21);
  line-height: pxToRem(28);

  @include media("tablet-landscape") {
    font-size: pxToRem(27);
    line-height: pxToRem(36);
  }
}
.subtitle2 {
  font-size: pxToRem(18);
  line-height: pxToRem(24);

  @include media("tablet-landscape") {
    font-size: pxToRem(22);
    line-height: pxToRem(28);
  }
}
.subtitle3 {
  font-size: pxToRem(17);
  line-height: pxToRem(20);
}

/* CAPTION */
.caption {
  @extend %base;
  font-weight: 400;
  font-size: pxToRem(15);
  line-height: pxToRem(16);

  a {
    color: inherit;
  }
}

/* PARAGRAPH */
.paragraph {
  @extend %base;
  font-weight: 400;
  letter-spacing: 0.05em;
}
.paragraph1 {
  font-size: pxToRem(22);
  line-height: 1.454545455;
}
.paragraph2 {
  font-size: pxToRem(17);
  line-height: 1.647058824;
}

@import "styles/variables";
@import "styles/mixins";

.header {
  text-align: center;
  background-image: url("../../../../assets/images/wisdomBg.jpg");
  background-clip: text;
  background-size: cover;
  background-position: center 45%;
  color: transparent;
  padding: $spacing-10 0;

  @include media("tablet-landscape") {
    padding: $spacing-15 0;
  }
}

.articleHeader {
  text-align: center;
}

.articleMeta {
  font-size: 1.0625rem;
  margin: $spacing-6 0;
  .meta {
    color: var(--color-fill-3);
  }
}

.section {
  margin-bottom: 30px;
}

.khaki-theme {
  --color-fill-1: #ffffff;
  --color-fill-1-reverse: #54685c;
  --color-fill-2: #ffffff;
  --color-fill-3: rgba(255, 255, 255, 0.75);
  --color-separator: rgba(255, 255, 255, 0.35);
  --color-background-1: #54685c;
  --color-background-1-reverse: #ffffff; //The main background color for the White Theme
  --color-background-2: #65776d;
}

@import "styles/variables";
@import "styles/mixins";

$loading-spinner-size-small: 20px;
$loading-spinner-size-mobile: 40px;
$loading-spinner-size-desktop: 80px;
$offset: 187;
$duration: 1.4s;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  &.isFullscreen {
    height: 100%;
  }
  &.isSmall {
    height: $loading-spinner-size-small + $spacing-5;
  }
}

.spinner {
  animation: rotator $duration linear infinite;
  width: $loading-spinner-size-mobile;
  height: $loading-spinner-size-mobile;

  @include media("tablet-landscape") {
    width: $loading-spinner-size-desktop;
    height: $loading-spinner-size-desktop;
  }

  .isSmall & {
    height: $loading-spinner-size-small;
    width: $loading-spinner-size-small;
  }

  :global(.background) {
    stroke: var(--color-separator);
  }

  :global(.path) {
    stroke: var(--color-primary-action-text);
    stroke-dasharray: $offset;
    stroke-dashoffset: 50;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

@import "styles/variables";
@import "styles/mixins";

.group {
  margin-bottom: $spacing-16;
  text-align: left;

  @include media("tablet-landscape") {
    margin-bottom: $spacing-24;
  }
}

.groupTitle {
  margin-bottom: $spacing-4;
}

.sessions {
  .row {
    border-bottom: solid 1px var(--color-separator);

    > div {
      padding-top: $spacing-6;
      padding-bottom: $spacing-6;
      display: flex;
      align-items: center;
      &:first-child {
        padding-left: 0 !important;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    }
    &:first-child {
      > div {
        padding-top: $spacing-4;
        padding-bottom: $spacing-4;
      }
    }
    .action {
      justify-content: flex-end;
    }

    a {
      text-decoration: none;
    }
  }
}

.sessionCardMobile {
  border-bottom: solid 1px var(--color-separator);
  padding: $spacing-4 0;

  &:first-child {
    border-top: solid 1px var(--color-separator);
  }
  .col2 {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  .caption {
    margin-top: $spacing-1;
  }

  a {
    text-decoration: none;
  }
}
